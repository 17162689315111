<template>
  <upload-excel :onSuccess="onSuccess" v-loading="loading"></upload-excel>
</template>

<script setup>
import UploadExcel from '@/components/UploadExcel'
import { ExcelImportAPI } from '../../../api/card.js'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { USER_RELATIONS, formatDate } from './utils'
import { ref } from 'vue'

// /**
//  *  1: 筛选数据
//  */
const generateData = (results) => {
  const arr = []
  results.forEach((item) => {
    const userInfo = {}
    Object.keys(item).forEach((key) => {
      if (USER_RELATIONS[key] === 'openTime') {
        userInfo[USER_RELATIONS[key]] = formatDate(item[key])
        return
      }
      userInfo[USER_RELATIONS[key]] = item[key]
    })
    arr.push(userInfo)
  })
  return arr
}

/**
 * 2: 数据解析成功之后的回调
 */
const router = useRouter()
const loading = ref(false)
const onSuccess = async ({ header, results }) => {
  try {
    loading.value = true // 开始导入时显示loading
    const updateData = generateData(results)
    // await graduatepayImportAPI(JSON.stringify(updateData))
    const res = await ExcelImportAPI(JSON.stringify(updateData))
    // console.log(res)
    ElMessage.success({
      // message: results.length + '条数据上传成功',
      message: res,
      type: 'success'
    })
    router.go(-1)
  } catch (error) {
    console.error(error)
    ElMessage.error({
      message: '导入失败，请重试',
      type: 'error'
    })
  } finally {
    loading.value = false // 无论成功或失败，都隐藏loading
  }
}
</script>
