/**
 * 导入数据对应表
 */
export const USER_RELATIONS = {
  卡号: 'card_number',
  卡密: 'card_password',
  总发货次数: 'card_times',
  到期时间: 'expiration_date',
  产品名称: 'proname'
}

/**
 * 解析 excel 导入的时间格式
 */
export const formatDate = (numb) => {
  const time = new Date((numb - 1) * 24 * 3600000 + 1)
  time.setYear(time.getFullYear() - 70)
  const year = time.getFullYear() + ''
  const month = time.getMonth() + 1 + ''
  const date = time.getDate() - 1 + ''
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  )
}
